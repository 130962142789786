import { Button } from "@nextui-org/react";
import AdversityNavbar from "../../components/Navbar/Navbar";
import HeroSection from "../../components/HeroSection/HeroSection";

const Home = () =>{
    return (
        <div>
        <AdversityNavbar/>
        <HeroSection/>
    </div>
    )
}

export default Home;