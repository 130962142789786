import { useState, useEffect } from "react";
import { XCircle } from "lucide-react";

const CountdownTimer = ({
  date = "30/01/2025", // Default date in DD/MM/YYYY format
  time = "02:00",     // Default time in HH:mm format
  onExpire = () => {}, // Callback function for expiration
}) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (!date || !time) {
      setIsExpired(true);
      onExpire(true);
      return;
    }
    

    const calculateTimeLeft = () => {
      try {
        // Split date and time into components
        const [day, month, year] = date.split("/").map(Number);
        const [hours, minutes] = time.split(":").map(Number);

        // Construct target date
        const targetDate = new Date(year, month - 1, day, hours, minutes);
        const now = new Date();
        const difference = targetDate - now;

        if (difference <= 0 || isNaN(difference)) {
          setIsExpired(true);
          onExpire(true);
          return null;
        }

        setIsExpired(false);
        onExpire(false);
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } catch (error) {
        console.error("Error calculating time left:", error);
        setIsExpired(true);
        onExpire(true);
        return null;
      }
    };

    const updateTimer = () => {
      const result = calculateTimeLeft();
      if (result) {
        setTimeLeft(result);
      }
    };

    // Initial calculation
    updateTimer();

    // Update countdown every second
    const timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer); // Cleanup timer on unmount
  }, [date, time, onExpire]);

  if (isExpired) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6 max-w-2xl mx-auto">
        <div className="flex items-center justify-center gap-3 text-red-500">
          <XCircle size={24} />
          <h2 className="text-xl font-semibold">Time Expired</h2>
        </div>
        <div className="mt-4 text-center text-gray-500 text-sm">
          Target was: {date || "Invalid date"} {time || "Invalid time"}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white  max-w-2xl mx-auto py-3">
      <div className="grid grid-cols-4 gap-4">
        <TimeUnit value={timeLeft.days} label="Days" />
        <TimeUnit value={timeLeft.hours} label="Hours" />
        <TimeUnit value={timeLeft.minutes} label="Minutes" />
        <TimeUnit value={timeLeft.seconds} label="Seconds" />
      </div>
    </div>
  );
};

const TimeUnit = ({ value = 0, label = "" }) => (
  <div className="flex flex-col items-center bg-gray-50 rounded-lg p-1 px-2">
    <div className="text-lg font-bold text-blue-600">
      {String(value).padStart(2, "0")}
    </div>
    <div className="text-xs text-gray-500 mt-1">{label}</div>
  </div>
);

export default CountdownTimer;