import { Icon } from "@iconify/react";
import { filterEvents } from "../../utils/eventData";
import { useEffect, useState } from "react";
import { Avatar, AvatarGroup, InputOtp } from "@nextui-org/react";
import { Calendar, Clock, IndianRupee, Banknote, Tickets } from "lucide-react";
import VideoCall from "../VidioCall/VidioCall";

const Upcoming = ({ eventData = [] }) => {
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    try {
      const filtered = filterEvents(eventData)?.upcoming || [];
      setFilteredEvents(filtered);
    } catch (error) {
      console.error("Error filtering events:", error);
    }
  }, [eventData]);

  return (
    <div className="min-h-[400px] max-h-[800px] overflow-auto">
      <div className="flex font-medium text-default-500">
        <Icon
          className="self-start"
          icon="solar:calendar-add-broken"
          height="22"
        />
        <h4 className="text-base dark:text-white ps-2">Upcoming Consultations</h4>
      </div>
      {filteredEvents.length > 0 ? (
        <div className="mt-4 mb-10">
          {filteredEvents.map((event, index) => (
            <div className="p-3 flex flex-wrap gap-8 justify-between border border-indigo-600 mt-8 rounded-lg">
              {/* Avatar Group */}
              <div className="flex gap-5">
                <AvatarGroup isBordered>
                  <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026704d" />
                  <Avatar src="https://i.pravatar.cc/150?u=a04258114e29026302d" />
                </AvatarGroup>
                {/* OTP Input */}
                <InputOtp
                  className="self-center"
                  description="OTP to Verify Consultant"
                  isReadOnly
                  defaultValue={event.consumer_otp || "----"}
                  length={4}
                  size="sm"
                />
              </div>
              {/* Event Details */}
              <div className="flex flex-wrap gap-5">
                {[
                  {
                    icon: <Calendar className="text-blue-500 h-4 w-4" />,
                    label: "Date",
                    value: event.time?.date || "TBA",
                  },
                  {
                    icon: <Clock className="text-blue-500 h-4 w-4" />,
                    label: "Time",
                    value: event.time?.time || "TBA",
                  },
                  {
                    icon: (
                      <IndianRupee className="text-blue-500 h-4 w-4" />
                    ),
                    label: "Cost",
                    value: event.payment?.price || "N/A",
                  },
                  {
                    icon: <Banknote className="text-blue-500 h-4 w-4" />,
                    label: "Payment Status",
                    value: event.amount_status?.toUpperCase() || "N/A",
                  },
                  {
                    icon: <Tickets className="text-blue-500 h-4 w-4" />,
                    label: "Session Status",
                    value: event.status?.toUpperCase() || "N/A",
                  },
                ].map(({ icon, label, value }) => (
                  <div className="flex items-center gap-2" key={label}>
                    <div className="p-2 bg-blue-50 rounded-md">{icon}</div>
                    <div className="flex flex-col">
                      <span className="text-xs text-gray-500 font-medium">
                        {label}
                      </span>
                      <span className="text-sm text-blue-950">{value}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-wrap gap-5">
                <VideoCall data={event} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex justify-center py-14">
          <img
            className="h-64 object-contain"
            src="https://i.ibb.co/bX4kLPy/5928293-2953960-1.png"
            alt="No upcoming events"
          />
        </div>
      )}
    </div>
  );
};

export default Upcoming;
