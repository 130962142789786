import { useEffect, useState } from "react";
import { Card, CardBody, Image, Button } from "@nextui-org/react";
import { queryFullData } from "../../utils/fetchFirebaseData";
import { Icon } from '@iconify/react';
import { Spinner } from "@nextui-org/react";

const Search = () => {
    const [consultants, setConsultants] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleImageError = (e) => {
        e.target.src = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHUndSzxcF1UbSXX3bVILVaUbSIhoc_GEA8g&s';
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const temp = await queryFullData("consultants");
                setConsultants(temp);
                console.log(temp)
            } catch (error) {
                console.error("Error fetching consultants:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>   <Spinner size="xl" /> </div>;
    }

    if (consultants.length === 0) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>  No consultants found.</div>;
    }

    return (
        <div className="container mx-auto max-w-4xl mt-6">
            {consultants.map((userData, index) => (
                <Card className="py-2 shadow-sm hover:shadow-md mx-1" key={userData.id || index}>
                    <CardBody className="overflow-visible py-2">
                        <div className="flex flex-row gap-6">
                            <div >
                                <img
                                    className="rounded-full h-28"
                                    src={userData.image}
                                    alt="User profile"
                                    onError={handleImageError}
                                />
                            </div>
                            <div className="my-auto">
                                <div className="font-semibold text-lg">{userData.displayname}</div>
                                <div className="text-xs">
                                    <div className="text-sm">{userData.headline}</div>
                                    <div className="text-gray-500 py-2 flex gap-3 flex-wrap">
                                        <span className="flex items-center"> {'@' + userData.username} </span>
                                        {userData.location.length ? <span className="flex items-center"> <Icon icon="mdi:map-marker" className="mr-1" />{userData.location}</span> : null}
                                        {/* <span className="flex items-center">
                                        <Icon icon="mdi:calendar" className="mr-1" />  {'Joined ' + userData.joined}
                                    </span> */}

                                        <span className="flex items-center">
                                            <Icon icon="mdi:star" className="mr-1 text-orange-500" /> {(userData.reviews.reduce((accum, curr) => accum + curr.rating, 0) / userData.reviews.length).toFixed(1)}({userData.reviews.length})
                                        </span>
                                    </div>
                                </div>
                                <div className="flex gap-4">
                                    <Button
                                        onPress={() => window.open('/profile/' + userData.username, '_blank')}
                                        size="sm"
                                        className="bg-transparent rounded text-black font-semibold tracking-wide p-0"
                                    >
                                        <Icon icon="ic:baseline-remove-red-eye" className="text-xl" />
                                        View Profile
                                    </Button>
                                    <Button
                                        onPress={() => window.open('/profile/' + userData.username, '_blank')}
                                        size="sm"
                                        className="bg-transparent rounded text-black p-0 font-semibold tracking-wide"
                                    >
                                        <Icon icon="ic:baseline-video-call" className="text-xl" />
                                        Book Meeting
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="text-sm py-3">{userData.description}</div>
                    </CardBody>
                </Card>
            ))}
        </div>
    );
};

export default Search;
