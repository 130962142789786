import React, { useMemo } from 'react';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';

const ReviewCard = ({
  username = "Anonymous",
  userImage = "https://www.veryicon.com/download/png/system/crm-android-app-icon/app-icon-person?s=256",
  rating = 4,
  date = "August 1, 2021",
  review = "As a highly skilled Software Engineer with 2 years of experience, I specialize in building robust, scalable, and secure web applications. My expertise spans full-stack development"
}) => {
  // Memoize the rating stars
  const stars = useMemo(() => {
    return [...Array(5)].map((_, i) => (
      <Icon
        key={i}
        icon={i < rating ? "ic:baseline-star" : "ic:baseline-star-border"}
        className={`${i < rating ? 'text-amber-400' : 'text-gray-200'}`}
        width={24}
        height={24}
      />
    ));
  }, [rating]);

  return (
    <div className="p-3 bg-white rounded-lg border-1 max-w-3xl w-full">
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center gap-3">
          <img 
            src={userImage} 
            alt={username}
            className="w-12 h-12 rounded-full object-cover"
          />
          <div>
            <h3 className="font-medium text-sm text-gray-900">{username}</h3>
            <span className="text-gray-500 text-xs">{date}</span>
          </div>
        </div>
        
        <div className="flex gap-1">
          {stars}
        </div>
      </div>

      <p className="text-gray-600 text-sm px-2">{review}</p>
    </div>
  );
};

ReviewCard.propTypes = {
  username: PropTypes.string,
  userImage: PropTypes.string,
  rating: PropTypes.number,
  date: PropTypes.string,
  review: PropTypes.string
};

export default ReviewCard;
