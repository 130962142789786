import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebaseAuth";

const getUserByEmail = async (email, usetype) => {
    try {
        // Ensure the collection name matches the Firestore database
        const q = query(collection(db, usetype), where("email", "==", email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log("No user found with the provided email.");
            return null;
        }

        // Return the first matching document
        const user = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];
        console.log("User found: ", user);
        return user;
    } catch (error) {
        console.error("Error fetching user by email: ", error);
    }
};

export default getUserByEmail;
