import { db } from "./firebaseAuth";
import { collection, query, where, getDocs, doc, updateDoc , arrayUnion } from "firebase/firestore";
import notify from "./toastService";

const queryUserData = async (table, searchByKey, keyValue, specificKeys = [] , isall = true) => {
    try {
        const q = query(
            collection(db, table),
            where(searchByKey, "==", keyValue),
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
           const data =   table=='consumers' || table=='consultants' ? notify.error("Sorry! We couldn't find any profile data") : null
            return null;
        }

        // Map the result to include the document ID
        const fetchedData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        return isall ? fetchedData[0] : fetchedData;
    } catch (error) {
        console.error("Error fetching user: ", error);
    }
};


const queryFullData = async (table) => {
    try {
        const q = query(
            collection(db, table),
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
           const data =   table=='consumers' || table=='consultants' ? notify.error("Sorry! We couldn't find any profile data") : null
            return null;
        }

        // Map the result to include the document ID
        const fetchedData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        return  fetchedData;
    } catch (error) {
        console.error("Error fetching user: ", error);
    }
};


const updateUserData = async (table, docId, updates) => {
    try {
        const docRef = doc(db, table, docId);
        await updateDoc(docRef, updates);
        return {
            success: true,
            message: "Data updated successfully."
        };
    } catch (error) {
        console.error("Error updating user data:", error);
        return {
            success: false,
            message: "Failed to update data. Please try again later."
        };
    }
};

const addReviewToConsultant = async (consultantId, review) => {
    try {
        const consultantDocRef = doc(db, "consultants", consultantId);
        await updateDoc(consultantDocRef, {
            reviews: arrayUnion(review),
        });
        notify.success("Review added successfully.");
        return {
            success: true,
            message: "Review added successfully."
        };
    } catch (error) {
        console.error("Error adding review:", error);
        notify.error("Failed to add review. Please try again later.");
        return {
            success: false,
            message: "Failed to add review. Please try again later."
        };
    }
};

export { queryUserData, updateUserData, addReviewToConsultant, queryFullData };
