import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Spinner } from "@nextui-org/react";

const ProtectedRoute = ({ children, redirectTo = "/login" }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      if (!user) {
        navigate(redirectTo); // Redirect to the dynamic path if not authenticated
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [auth, navigate, redirectTo]);

  if (loading) {
    // Show a spinner while checking the auth state
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner size="xl" />
      </div>
    );
  }

  // Render the protected content if authenticated, else return null
  return user ? children : null;
};

export default ProtectedRoute;
