import CountdownTimer from "../Timer/Timer";
import React, { useState, useEffect, useRef } from 'react';
import DailyIframe from '@daily-co/daily-js';
import './VidioCall.css'
import { Icon } from "@iconify/react";
import {Button} from "@nextui-org/react"

const VideoCall = ({ data }) => {
   const [isExpired, setIsExpired] = useState(false);
   const [isPreview, setIsPreview] = useState(true);
   const [isCameraOn, setIsCameraOn] = useState(true);
   const [isMicOn, setIsMicOn] = useState(true);
   const [selectedCamera, setSelectedCamera] = useState('');
   const [selectedMic, setSelectedMic] = useState('');
   const [devices, setDevices] = useState({ cameras: [], mics: [] });
   const [localVideo, setLocalVideo] = useState(null);
   const callFrameRef = useRef(null);

   useEffect(() => {
      if (isExpired) {
         const initPreview = async () => {
            try {
               const stream = await navigator.mediaDevices.getUserMedia({
                  video: true,
                  audio: true,
               });
               const devices = await navigator.mediaDevices.enumerateDevices();

               const cameras = devices.filter((device) => device.kind === 'videoinput');
               const mics = devices.filter((device) => device.kind === 'audioinput');

               setDevices({ cameras, mics });
               if (cameras.length > 0) setSelectedCamera(cameras[0].deviceId);
               if (mics.length > 0) setSelectedMic(mics[0].deviceId);

               setLocalVideo(stream);
            } catch (error) {
               console.error('Error initializing preview:', error);
               alert('Failed to access camera/microphone. Please check your permissions.');
            }
         };

         if (isPreview) {
            initPreview();
         }
      }


      return () => {
         if (localVideo) {
            localVideo.getTracks().forEach((track) => track.stop());
         }
      };
   }, [isPreview,isExpired]);

   const handleDeviceChange = async (deviceId, type) => {
      try {
         if (type === 'camera') {
            setSelectedCamera(deviceId);
         } else {
            setSelectedMic(deviceId);
         }
         const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: selectedCamera } },
            audio: { deviceId: { exact: selectedMic } },
         });
         setLocalVideo(stream);
      } catch (error) {
         console.error('Error changing device:', error);
      }
   };

   const toggleCamera = () => {
      setIsCameraOn((prev) => !prev);
      if (localVideo) {
         localVideo.getVideoTracks().forEach((track) => {
            track.enabled = !isCameraOn;
         });
      }
   };

   const toggleMic = () => {
      setIsMicOn((prev) => !prev);
      if (localVideo) {
         localVideo.getAudioTracks().forEach((track) => {
            track.enabled = !isMicOn;
         });
      }
   };

   const startMeeting = async () => {
      try {
         if (localVideo) {
            localVideo.getTracks().forEach((track) => track.stop());
         }

         const callFrame = DailyIframe.createFrame({
            showLeaveButton: true,
            iframeStyle: {
               position: 'fixed',
               top: 0,
               left: 0,
               width: '100%',
               height: '100vh',
               border: '0',
               zIndex: 200,
               backgroundColor: '#fff'
            },
         });

         callFrameRef.current = callFrame;

         await callFrame.join({
            url: 'https://adversitytest.daily.co/gHYNEnNqrS1NxzJ8IsUT',
         });

         callFrame.on('joined-meeting', () => {
            if (!isCameraOn) callFrame.setLocalVideo(false);
            if (!isMicOn) callFrame.setLocalAudio(false);
            if (selectedCamera) {
               callFrame.setInputDevicesAsync({ videoDeviceId: selectedCamera });
            }
            if (selectedMic) {
               callFrame.setInputDevicesAsync({ audioDeviceId: selectedMic });
            }
         });

         setIsPreview(false);
      } catch (error) {
         console.error('Error starting meeting:', error);
         alert('Failed to start meeting. Please try again.');
      }
   };

   return (
      <div>
         {isExpired ? (
            <div className="flex flex-col py-3">
               {isPreview ? (
                  <div className="flex flex-1 gap-4">
                           <Button onPress={startMeeting} size="sm" className="bg-blue-950 rounded text-white shadow-sm py-5 px-4 font-semibold tracking-wide"> <Icon icon="ic:baseline-video-call" className="text-xl" /> Join Meeting </Button>
                  </div>
               ) : (
                  <div className='relative'>
                     <div
                        id="meeting-container"
                        className="relative inset-0 bg-gray-900 rounded-lg overflow-hidden"
                     >
                     </div>
                  </div>

               )}
            </div>
         ) : (
            <CountdownTimer
               date={data?.time?.date}
               time={data?.time?.time}
               onExpire={setIsExpired}
            />
         )}
      </div>
   );
};

export default VideoCall;
// 'https://adversitytest.daily.co/gHYNEnNqrS1NxzJ8IsUT'
