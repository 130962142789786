import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { checkAuthStatus } from "../../utils/firebaseAuth";
import { Spinner, Alert, Button } from "@nextui-org/react";
import GoogleLogin from "../../pages/Login/GoogleLogin";
import { queryUserData, updateUserData } from "../../utils/fetchFirebaseData";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebaseAuth";
import notify from "../../utils/toastService";

const BookConsultation = ({ data }) => {
    const navigate = useNavigate();
    const [selection, setSelection] = useState({ date: null, time: null });
    const [userDetail, setUserDetail] = useState(null);
    const [userBookingState, setUserBookingState] = useState({ state: null, message: '', route: '/' });
    console.log(data)
    // Sample data
    const availableDates = data.selectedDates;
    // [
    //     "23/01/2025", "24/02/2025", "24/03/2025", "24/12/2024",
    //     "25/02/2025", "26/03/2025", "27/12/2024", "28/12/2024",
    //     "29/01/2025", "30/01/2025", "30/12/2024", "31/12/2024"
    // ];

    const timeSlots = data.selectedTimeSlots;

    const bookedSlots = data.bookedSlots

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const loggedInUser = await checkAuthStatus();
                if (!loggedInUser) {
                    setUserDetail(null);
                } else {
                    setUserDetail(loggedInUser);
                    console.log(loggedInUser)
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, []);

    const bookedSet = useMemo(() => {
        const set = new Set();
        bookedSlots.forEach((slot) => set.add(`${slot.date}-${slot.time}`));
        return set;
    }, [bookedSlots]);

    const isDateFullyBooked = (date) => {
        const bookedTimesForDate = bookedSlots.filter((slot) => slot.date === date).length;
        return bookedTimesForDate === timeSlots.length;
    };

    const isSlotBooked = (date, time) => bookedSet.has(`${date}-${time}`);

    const getButtonStyles = (isSelected, isBooked) =>
        `p-3 rounded-lg border transition-all duration-200 ${isSelected
            ? "border-blue-500 bg-blue-50 text-blue-700 shadow-sm"
            : isBooked
                ? "bg-gray-50 text-gray-400 cursor-not-allowed"
                : "hover:border-blue-200 hover:bg-blue-50/50"
        }`;

    const handleDateSelect = useCallback(
        (date) => {
            if (!isDateFullyBooked(date)) {
                setSelection((prev) => ({ ...prev, date }));
            }
        },
        [isDateFullyBooked]
    );

    const handleTimeSelect = useCallback(
        (time) => {
            if (!isSlotBooked(selection.date, time)) {
                setSelection((prev) => ({ ...prev, time }));
            }
        },
        [selection.date, isSlotBooked]
    );

    const renderDateButton = useCallback(
        (date) => {
            const isBooked = isDateFullyBooked(date);
            const isSelected = selection.date === date;
            return (
                <button
                    key={date}
                    onClick={() => handleDateSelect(date)}
                    disabled={isBooked}
                    className={getButtonStyles(isSelected, isBooked)}
                >
                    <span className="block font-medium">{date}</span>
                    {isBooked && <span className="block text-xs mt-1 text-gray-400">Fully Booked</span>}
                </button>
            );
        },
        [selection.date, handleDateSelect, isDateFullyBooked]
    );

    const renderTimeButton = useCallback(
        (time) => {
            const isBooked = isSlotBooked(selection.date, time);
            const isSelected = selection.time === time;
            return (
                <button
                    key={time}
                    onClick={() => handleTimeSelect(time)}
                    disabled={isBooked}
                    className={getButtonStyles(isSelected, isBooked)}
                >
                    <span className="block font-medium">{time}</span>
                    {isBooked && <span className="block text-xs mt-1 text-gray-400">Booked</span>}
                </button>
            );
        },
        [selection.date, selection.time, handleTimeSelect, isSlotBooked]
    );

    const confirmBookslot = async () => {
        try {
            // Prevent self-booking
            if (data.email === userDetail.email) {
                setUserBookingState({
                    state: 'failed',
                    message: "You cannot book a consultation with yourself.",
                    route: '/'
                });
                return;
            }

            // Indicate booking in progress
            setUserBookingState({
                state: 'inprogress',
                message: 'Processing your booking, please wait...',
                route: '/'
            });

            // Fetch consultant data
            const consultantData = await queryUserData("consultants", "username", data.username, []);

            // Check if the selected slot is already booked
            const isSlotTaken = consultantData.bookedSlots.some(
                (slot) => slot.date === selection.date && slot.time === selection.time
            );

            if (isSlotTaken) {
                setUserBookingState({
                    state: 'failed',
                    message: 'The selected slot is unavailable. Please choose a different time.',
                    route: '/'
                });
                return;
            }

            // Prepare booking details
            const bookingDetails = {
                consumer: userDetail.email,
                consultant: data.email,
                consultant_username: data.username,
                consultant_id: data.id,
                consumer_otp: Math.floor(1000 + Math.random() * 9000).toString(),
                consultant_otp: '',
                status: 'upcoming',
                commission: data.commission,
                payment: {
                    currency: data.currency,
                    price: data.price
                },
                time: {
                    date: selection.date,
                    time: selection.time
                },
                amount_status: 'hold',
            };

            // Save booking to database
            try {
                const docRef = await addDoc(collection(db, "events"), bookingDetails);
                const updates = { bookedSlots: [...bookedSlots, bookingDetails.time] }
                const updateConsultant = await updateUserData('consultants', data.id, updates)
                console.log(updateConsultant)
                notify.default("Your meeting has been successfully scheduled.");
            } catch (dbError) {
                setUserBookingState({
                    state: 'failed',
                    message: 'Failed to schedule your meeting. Please try again later.',
                    route: '/'
                });
                console.error("Database error:", dbError);
                return;
            }

            // Update booking state to scheduled
            setUserBookingState({
                state: 'scheduled',
                message: 'Your consultation has been successfully scheduled!',
                route: '/'
            });

            console.log("Booking details:", bookingDetails);
        } catch (error) {
            // Handle unexpected errors
            setUserBookingState({
                state: 'failed',
                message: 'An unexpected error occurred while processing your booking. Please try again.',
                route: '/'
            });
            console.error("Error during booking:", error);
        }
    };





    return (
        <div className="w-full max-w-4xl mx-auto bg-white rounded-xl relative">
            {userDetail === null ? (
                <GoogleLogin prevPath="/profile/ashutosh" />
            ) : userBookingState.state === "inprogress" ? (
                <div className="flex justify-center items-center h-[70vh]">
                    <Spinner size="xl" color="secondary" label="Scheduling Meeting in Progress" labelColor="secondary" />
                </div>
            ) : userBookingState.state === "scheduled" ? (
                <div className="flex justify-center items-center h-[70vh]">
                    <Alert
                        color="success"
                        endContent={<Button onPress={() => navigate('/dashboard')} color="success" size="sm" variant="flat">View Event</Button>}
                        title="New Meeting Scheduled"
                        variant="faded"
                    />
                </div>
            ) : userBookingState.state === "failed" ? (
                <div className="flex justify-center items-center h-[70vh]">
                    <Alert
                        color="warning"
                        endContent={<Button onPress={() => setUserBookingState({ state: null, message: '', route: '/' })} color="warning" size="sm" variant="flat">Try Again</Button>}
                        title={userBookingState.message}
                        variant="faded"
                    />
                </div>
            ) : (
                <div className="max-h-[70vh] overflow-auto pb-16">
                    <div className="space-y-8">
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold flex items-center gap-2 text-gray-700">
                                <Icon icon="mdi:calendar-solar" className="text-blue-500 w-6 h-6" />
                                Select Date
                            </h3>
                            <div className="grid grid-cols-2 gap-3">{availableDates.map(renderDateButton)}</div>
                        </div>
                        {selection.date && (
                            <div className="space-y-4 pb-10">
                                <h3 className="text-lg font-semibold flex items-center gap-2 text-gray-700">
                                    <Icon icon="mdi:clock-time-four-outline" className="w-5 h-5" />
                                    Select Time
                                </h3>
                                <div className="grid grid-cols-3 gap-3">{timeSlots.map(renderTimeButton)}</div>
                            </div>
                        )}
                        {selection.date && selection.time && (
                            <div className="absolute bottom-0 p-4 left-0 right-0 bg-green-50 border border-green-200 rounded-lg">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-green-700 font-medium">Selected Date & Time</h4>
                                        <p className="text-green-600 mt-1">{selection.date} at {selection.time}</p>
                                    </div>
                                    <button onClick={confirmBookslot} className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors">
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookConsultation;
