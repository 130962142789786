import React, { useState, useEffect } from 'react';

const CurrencyCounter = ({ 
  value = "₹ 400", 
  duration = 0.1,
  className = ""
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Extract numeric value from the currency string
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
    const symbol = value.replace(/[0-9.-]+/g, "").trim();
    
    // If the value is 0, set count to 0 immediately
    if (numericValue === 0) {
      setCount(0);
      return;
    }

    // Convert duration to milliseconds (0.1s = 100ms)
    const durationMs = duration * 1000;
    
    // Animate the counter
    let start = 0;
    const end = numericValue;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(durationMs / range));
    
    const timer = setInterval(() => {
      start += increment;
      setCount(start);
      
      if (start === end) {
        clearInterval(timer);
      }
    }, stepTime);
    
    return () => clearInterval(timer);
  }, [value, duration]);

  return (
    <dd className={`px-4 text-2xl font-semibold text-gray-700 transition-all duration-200 ${className}`}>
      {value.charAt(0)}{' '}{Math.floor(count).toLocaleString()}
    </dd>
  );
};

export default CurrencyCounter;
