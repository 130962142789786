
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, Bounce } from 'react-toastify';

import NotFound from './pages/NotFound/NotFound';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Home from './pages/Home/Home';
import ProtectedRoute from './utils/ProtectedRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import GoogleLogin from './pages/Login/GoogleLogin';
import RegisterConsultant from './pages/RegisterConsultant/RegisterConsultant';
import PolicyComponent from './pages/Privacy/Privacy';
import AdversityNavbar from './components/Navbar/Navbar';
import Profile from './pages/Profile/Profile';
import VideoCall from './components/VidioCall/VidioCall';
import Search from './pages/Search/Search';
import SearchNavbar from './pages/Search/SearchNavbar';


function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<ProtectedRoute>  <AdversityNavbar /> <Dashboard /> </ProtectedRoute>} />
        <Route path="/register-as-consultant" element={<ProtectedRoute> <AdversityNavbar /> <RegisterConsultant /> </ProtectedRoute>} />
        <Route path="/login" element={<GoogleLogin />} />
        <Route path="/profile/:username" element={<div> <AdversityNavbar /> <Profile /> </div>} />
        <Route path="/search/:prompt" element={<div> <SearchNavbar/>  <Search/></div>} />
        <Route path="/privacy-policy" element={<PolicyComponent />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
