import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { Tabs, Tab, Card } from "@nextui-org/react";
import PersonalDashboard from "../../components/PersonalDashboard/PersonalDashboard";
import ConsultantDashboard from "../../components/ConsultantDashboard/ConsultantDashboard";
import { checkAuthStatus } from "../../utils/firebaseAuth";
import getUserByEmail from "../../utils/getUserDetail";

const Dashboard = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("personal_dashboard");
  const [consultantData, setConsultantData] = useState(null);
  const [consumerData, setConsumerData] = useState(null);
  const [error, setError] = useState(null); // State for error handling
  const [loading, setLoading] = useState(true); // State for loading status

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const loggedInUser = await checkAuthStatus();
        if (!loggedInUser) {
          navigate("/login");
          return;
        }

        const [consultant, consumer] = await Promise.all([
          getUserByEmail(loggedInUser.email, "consultants"),
          getUserByEmail(loggedInUser.email, "consumers"),
        ]);

        setConsultantData(consultant);
        setConsumerData(consumer);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  // Memoize dashboards to avoid unnecessary re-renders
  const consultantDashboard = useMemo(
    () =>
      consultantData && (
        <ConsultantDashboard consultantData={consultantData} />
      ),
    [consultantData]
  );

  const personalDashboard = useMemo(
    () =>
      consumerData && <PersonalDashboard userData={consumerData} />,
    [consumerData]
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col relative isolate px-6 pt-28 lg:px-8">
      <Tabs
        aria-label="Dashboard Options"
        selectedKey={selected}
        onSelectionChange={setSelected}
      >
        {consultantData && (
          <Tab key="consultant_dashboard" title="Consultant Dashboard">
            <Card className="p-5">{consultantDashboard}</Card>
          </Tab>
        )}
        {consumerData && (
          <Tab key="personal_dashboard" title="Personal Dashboard">
            <Card className="p-5">{personalDashboard}</Card>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default Dashboard;
