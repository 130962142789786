import React, { useEffect, useState } from 'react';
// 
const MultiSelectCalendar = ({ 
  additionalDisabledDates = [],
  removeSlots = [],
  minDate = new Date(),
  getSelectedValue
}) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);


  useEffect(()=>{getSelectedValue({selectedDates,selectedTimeSlots})},[selectedDates,selectedTimeSlots])

  // Calendar helper functions
  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const getPreviousMonthDays = (date) => {
    const firstDay = getFirstDayOfMonth(date);
    if (firstDay === 0) return [];
    
    const previousMonth = new Date(date.getFullYear(), date.getMonth() - 1);
    const daysInPreviousMonth = getDaysInMonth(previousMonth);
    const days = [];
    
    for (let i = daysInPreviousMonth - firstDay + 1; i <= daysInPreviousMonth; i++) {
      days.push({
        date: new Date(previousMonth.getFullYear(), previousMonth.getMonth(), i),
        isPreviousMonth: true
      });
    }
    
    return days;
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB'); // or use your preferred locale
  };

  const isDateDisabled = (date) => {
    const dateStr = formatDate(date);
    const today = formatDate(new Date());
    
    return (
      dateStr <= today ||
      additionalDisabledDates.includes(dateStr)
    );
  };

  const toggleDate = (date) => {
    if (isDateDisabled(date)) return;
    
    const dateStr = formatDate(date);
    if (selectedDates.includes(dateStr)) {
      setSelectedDates(selectedDates.filter(d => d !== dateStr));
    } else {
      setSelectedDates([...selectedDates, dateStr]);
    }
  };

  const changeMonth = (offset) => {
    const newMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + offset);
    if (offset < 0) {
      const today = new Date();
      if (newMonth.getFullYear() < today.getFullYear() || 
          (newMonth.getFullYear() === today.getFullYear() && newMonth.getMonth() < today.getMonth())) {
        return;
      }
    }
    setCurrentMonth(newMonth);
  };

  const isPrevMonthDisabled = () => {
    const today = new Date();
    return (
      currentMonth.getFullYear() === today.getFullYear() &&
      currentMonth.getMonth() <= today.getMonth()
    );
  };

  const generateCalendarDays = () => {
    const days = [];
    
    // Add previous month's days
    const previousMonthDays = getPreviousMonthDays(currentMonth);
    previousMonthDays.forEach(({ date }) => {
      days.push(
        <button
          key={`prev-${formatDate(date)}`}
          disabled={true}
          className="h-10 w-10 rounded-full flex items-center justify-center text-gray-300 bg-transparent cursor-not-allowed"
          type="button"
        >
          {date.getDate()}
        </button>
      );
    });

    // Add current month's days
    const daysInMonth = getDaysInMonth(currentMonth);
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
      const dateStr = formatDate(date);
      const isSelected = selectedDates.includes(dateStr);
      const isDisabled = isDateDisabled(date);
      const isToday = formatDate(new Date()) === dateStr;

      days.push(
        <button
          key={dateStr}
          onClick={() => toggleDate(date)}
          disabled={isDisabled}
          className={`
            h-10 w-10 rounded-full flex items-center justify-center
            transition-colors duration-200
            ${isSelected ? 'bg-blue-500 text-white hover:bg-blue-600' : 
              isDisabled ? 'bg-gray-100 text-gray-400 cursor-not-allowed' :
              'hover:bg-gray-100 text-gray-700'}
            ${isToday ? 'ring-2 ring-gray-200' : ''}
          `}
          type="button"
        >
          {day}
        </button>
      );
    }

    // Add next month's days to fill the grid
    const totalDays = days.length;
    const remainingCells = 42 - totalDays; // 6 rows * 7 days = 42
    for (let i = 1; i <= remainingCells; i++) {
      days.push(
        <button
          key={`next-${i}`}
          disabled={true}
          className="h-10 w-10 rounded-full flex items-center justify-center text-gray-300 bg-transparent cursor-not-allowed"
          type="button"
        >
          {i}
        </button>
      );
    }

    return days;
  };

  // Time slot helper functions
  const formatTime = (hour) => {
    return `${hour.toString().padStart(2, '0')}:00`;
  };

  const toggleTimeSlot = (hour) => {
    const timeSlot = formatTime(hour);
    if (selectedTimeSlots.includes(timeSlot)) {
      setSelectedTimeSlots(selectedTimeSlots.filter(slot => slot !== timeSlot));
    } else {
      setSelectedTimeSlots([...selectedTimeSlots, timeSlot].sort());
    }
  };


  const generateTimeSlots = () => {
    const slots = [];
    
    // Loop through all 24 hours and generate time slots
    for (let i = 0; i < 24; i++) {
      const timeSlot = formatTime(i);
      
      // If the time slot is in the removeSlots array, skip it
      if (removeSlots.includes(timeSlot)) {
        continue;
      }
  
      const isSelected = selectedTimeSlots.includes(timeSlot);
  
      slots.push(
        <button
          key={timeSlot}
          onClick={() => toggleTimeSlot(i)}
          className={`
            p-2 rounded-lg text-sm font-medium
            transition-colors duration-200
            ${isSelected 
              ? 'bg-blue-500 text-white hover:bg-blue-600' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}
            flex items-center justify-center
          `}
          type="button"
        >
          {timeSlot}
        </button>
      );
    }
  
    return slots;
  };

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return (
    <div className="w-full max-w-4xl mx-auto bg-white rounded-xl shadow-md p-6 my-2">
      <div className="">
        {/* Calendar Section */}
        <div className="flex-1">
          {/* Calendar Header */}
          <div className="flex justify-between items-center mb-6">
            <button
              onClick={() => changeMonth(-1)}
              disabled={isPrevMonthDisabled()}
              className={`p-2 rounded-full ${
                isPrevMonthDisabled() 
                  ? 'text-gray-300 cursor-not-allowed' 
                  : 'hover:bg-gray-100 text-gray-700'
              }`}
              type="button"
            >
              ←
            </button>
            <h2 className="text-lg font-semibold">
              {monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}
            </h2>
            <button
              onClick={() => changeMonth(1)}
              className="p-2 hover:bg-gray-100 rounded-full text-gray-700"
              type="button"
            >
              →
            </button>
          </div>

          {/* Week days header */}
          <div className="grid grid-cols-7 gap-1 mb-2">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <div key={day} className="text-center text-sm font-medium text-gray-500">
                {day}
              </div>
            ))}
          </div>

          {/* Calendar grid */}
          <div className="grid grid-cols-7 gap-1">
            {generateCalendarDays()}
          </div>

          {/* Selected dates display */}
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Selected Dates:</h3>
            <div className="flex flex-wrap gap-2">
              {selectedDates.sort().map(date => (
                <div
                  key={date}
                  className="bg-blue-100 text-blue-800 text-sm px-3 py-1 rounded-full flex items-center gap-2"
                >
                  {date}
                  <button
                    onClick={() => toggleDate(new Date(date))}
                    className="hover:bg-blue-200 rounded-full p-1"
                    type="button"
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Time Slots Section */}
        <div className="flex-1">
          <h3 className="text-lg font-semibold mb-4">Available Time Slots</h3>
          <div className="grid grid-cols-4 gap-2">
            {generateTimeSlots()}
          </div>
          
          {/* Selected time slots display */}
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Selected Time Slots:</h3>
            <div className="flex flex-wrap gap-2">
              {selectedTimeSlots.map(time => (
                <div
                  key={time}
                  className="bg-blue-100 text-blue-800 text-sm px-3 py-1 rounded-full flex items-center gap-2"
                >
                  {time}
                  <button
                    onClick={() => toggleTimeSlot(parseInt(time))}
                    className="hover:bg-blue-200 rounded-full p-1"
                    type="button"
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Combined selection summary */}
      <div className="mt-6 pt-4 border-t">
        <h3 className="text-sm font-medium text-gray-700 mb-2">Selected Schedule:</h3>
      
        <div className="flex flex-wrap gap-2">
          {selectedDates.sort().map(date => 
            selectedTimeSlots.map(time => (
              <div
                key={`${date}-${time}`}
                className="bg-green-100 text-green-800 text-sm px-3 py-1 rounded-full"
              >
                {date} at {time}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectCalendar;
