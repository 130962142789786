import React, { useState } from 'react';

const PolicyComponent = () => {
  const [selectedPolicy, setSelectedPolicy] = useState("privacy");
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (policyKey, sectionIndex) => {
    setExpandedSections(prev => ({
      ...prev,
      [`${policyKey}-${sectionIndex}`]: !prev[`${policyKey}-${sectionIndex}`]
    }));
  };

  const policies = {
    privacy: {
      title: "Privacy Policy",
      introduction: "At Adversity, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and protect your data when you use our platform.",
      sections: [
        {
          title: "Data Collection",
          content: "We collect personal information such as your name, email address, phone number, payment details, and consultation history when you register, use our services, or interact with the platform."
        },
        {
          title: "Data Usage",
          content: "We use your data to:\n• Provide and improve our consultation services.\n• Process payments and manage transactions.\n• Send you important updates and promotional content (if opted-in).\n• Respond to customer support inquiries."
        },
        {
          title: "Data Sharing",
          content: "We do not share your personal information with third parties, except for:\n• Payment processors (e.g., PhonePe) to process transactions securely.\n• Service providers assisting with platform functionality, marketing, and customer support.\n• Legal obligations or in the event of a merger or acquisition."
        },
        {
          title: "Data Security",
          content: "We take appropriate security measures to protect your data, including encryption and secure storage. However, no method of transmission over the internet is completely secure."
        },
        {
          title: "User Rights",
          content: "You have the right to:\n• Access, update, or delete your personal information.\n• Opt-out of promotional emails by clicking the unsubscribe link in the email."
        }
      ]
    },
    terms: {
      title: "Terms of Service",
      introduction: "By accessing or using the Adversity platform, you agree to these Terms of Service. Please read them carefully.",
      sections: [
        {
          title: "Account Registration",
          content: "To use our services, you must create an account. You must be at least 18 years old to register as a consultant."
        },
        {
          title: "User Responsibilities",
          content: "• Consultants: You are responsible for providing accurate information about your expertise and services.\n• Clients: You are responsible for providing accurate problem descriptions and ensuring timely payment for consultations."
        },
        {
          title: "Consultation Terms",
          content: "• The terms of each consultation (e.g., fees, duration) are agreed upon between the consultant and client.\n• Payments are processed securely through PhonePe, and a commission is charged by Adversity."
        }
      ]
    },
    cookies: {
      title: "Cookie Policy",
      introduction: "This Cookie Policy explains how Adversity uses cookies and similar technologies on our platform.",
      sections: [
        {
          title: "What are Cookies?",
          content: "Cookies are small files stored on your device that help us provide a better user experience. We use cookies to:\n• Remember your login credentials.\n• Track your activity on our platform for analytics.\n• Serve personalized advertisements."
        },
        {
          title: "Types of Cookies We Use",
          content: "• Essential Cookies: Required for the basic functioning of the platform.\n• Performance Cookies: Help us understand how users interact with our platform.\n• Targeting Cookies: Used for advertising and personalized content."
        }
      ]
    },
    payment: {
      title: "Payment Policy",
      introduction: "This Payment Policy outlines how payments are processed on Adversity.",
      sections: [
        {
          title: "Payment Methods",
          content: "We accept payments through PhonePe and other secure payment methods. All payments for consultations and subscriptions are processed securely."
        },
        {
          title: "Payment Processing",
          content: "• PhonePe processes all payment transactions on our platform. We do not store your payment details.\n• Fees for consultations are determined by the consultant and client.\n• Adversity retains a commission on each consultation fee."
        }
      ]
    },
    consultant: {
      title: "Consultant Agreement",
      introduction: "This agreement outlines the terms for consultants using Adversity to offer services.",
      sections: [
        {
          title: "Service Delivery",
          content: "As a consultant, you agree to provide services as described in your profile and honor the consultation agreements with clients."
        },
        {
          title: "Payment and Commission",
          content: "You will receive a percentage of the consultation fee, with Adversity retaining the remaining amount as a commission."
        },
        {
          title: "Ratings and Reviews",
          content: "Consultants agree to accept feedback and ratings from clients. Negative reviews can be disputed if deemed inappropriate."
        }
      ]
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6">    
          {/* Tabs */}
          <div className="flex flex-wrap gap-2 mb-6 border-b">
            {Object.entries(policies).map(([key, policy]) => (
              <button
                key={key}
                onClick={() => setSelectedPolicy(key)}
                className={`px-4 py-2 rounded-t-lg transition-colors duration-200 ${
                  selectedPolicy === key
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                }`}
              >
                {policy.title}
              </button>
            ))}
          </div>

          {/* Content */}
          <div className="policy-content">
            <h2 className="text-xl font-semibold mb-4">
              {policies[selectedPolicy].title}
            </h2>
            <p className="mb-6 text-gray-600">
              {policies[selectedPolicy].introduction}
            </p>

            {/* Accordion Sections */}
            <div className="space-y-2">
              {policies[selectedPolicy].sections.map((section, index) => (
                <div
                  key={index}
                  className="border rounded-lg overflow-hidden"
                >
                  <button
                    onClick={() => toggleSection(selectedPolicy, index)}
                    className="w-full px-4 py-3 text-left bg-gray-50 hover:bg-gray-100 flex justify-between items-center"
                  >
                    <span className="font-medium">{section.title}</span>
                    <span className="transform transition-transform duration-200">
                      {expandedSections[`${selectedPolicy}-${index}`] ? '−' : '+'}
                    </span>
                  </button>
                  {expandedSections[`${selectedPolicy}-${index}`] && (
                    <div className="px-4 py-3 bg-white whitespace-pre-line">
                      {section.content}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyComponent;