import { useState } from 'react';
import { Star } from 'lucide-react';

const Rating = ({ 
  total = 5, 
  initialValue = 0, 
  onChange,
  readOnly = false 
}) => {
  const [rating, setRating] = useState(initialValue);
  const [hover, setHover] = useState(null);

  const handleClick = (value) => {
    if (!readOnly) {
      setRating(value);
      onChange?.(value);
    }
  };

  return (
    <div className="flex gap-1">
      {[...Array(total)].map((_, index) => {
        const value = index + 1;
        return (
          <button
            key={index}
            type="button"
            disabled={readOnly}
            onClick={() => handleClick(value)}
            onMouseEnter={() => !readOnly && setHover(value)}
            onMouseLeave={() => !readOnly && setHover(null)}
            className={`
              transition-transform duration-200 
              ${!readOnly && 'hover:scale-110'}
              ${readOnly ? 'cursor-default' : 'cursor-pointer'}
            `}
          >
            <Star
              size={24}
              className={`
                transition-colors duration-200
                ${(hover || rating) >= value 
                  ? 'fill-yellow-400 text-yellow-400' 
                  : 'fill-gray-200 text-gray-200'}
              `}
            />
          </button>
        );
      })}
      {!readOnly && (
        <span className="ml-2 text-gray-600">
          {rating ? `${rating} of ${total}` : 'Rate'}
        </span>
      )}
    </div>
  );
};


export default Rating;



// Interactive rating input
{/* <Rating 
  total={5} 
  initialValue={0} 
  onChange={(value) => console.log('New rating:', value)} 
/>

// Read-only display
<Rating 
  total={5} 
  initialValue={4} 
  readOnly={true} 
/> */}