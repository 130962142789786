function filterEvents(events) {
    const currentDateTime = new Date(); // Get the current date and time
  
    const upcoming = [];
    const previous = [];
  
    events.forEach(event => {
      const { date, time } = event.time;
      const [day, month, year] = date.split('/').map(Number); // Split and parse the date
      const [hour, minute] = time.split(':').map(Number); // Split and parse the time
  
      // Construct a Date object for the event
      const eventDateTime = new Date(year, month - 1, day, hour, minute);
  
      // Compare the event time with the current time
      if (eventDateTime >= currentDateTime) {
        upcoming.push(event);
      } else {
        previous.push(event);
      }
    });
  
    return { upcoming, previous };
  }
  

  export { filterEvents }
  