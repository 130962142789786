import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Textarea, Select, SelectItem } from "@nextui-org/react";
import getUserByEmail from "../../utils/getUserDetail";
import MultiSelectCalendar from "../../components/MultiSelectCalendar/MultiSelectCalendar";
import { checkAuthStatus,db } from "../../utils/firebaseAuth";
import { collection, addDoc } from "firebase/firestore";
import notify from "../../utils/toastService";
import { Spinner } from "@nextui-org/react";

export default function RegisterConsultant() {
    const [user,setUser] = useState(null)
    const [action, setAction] = useState(null);
    const [languageValues, setLanguageValues] = useState(new Set());
    const [keywords, setKeywords] = useState([]);
    const [currentKeyword, setCurrentKeyword] = useState("");
    const [calenderValue, setCalenderValue] = useState({ selectedDates: [], selectedTimeSlots: [] })
    const navigate = useNavigate();

    // Memoizing the language options to prevent unnecessary recalculations on every render
    const languages = useMemo(() => [
        "English", "Hindi", "Mandarin Chinese", "Spanish", "Bengali", "Arabic", "Portuguese",
        "Russian", "Urdu", "Punjabi", "Japanese", "German", "Korean", "French", "Telugu",
        "Marathi", "Tamil", "Turkish", "Italian", "Gujarati", "Thai", "Malayalam",
        "Kannada", "Swahili", "Hausa", "Javanese", "Vietnamese", "Persian"
    ].map((label) => ({ key: label.toLowerCase().replace(/ /g, "-"), label })), []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const loggedInUser = await checkAuthStatus();
                setUser(loggedInUser)
                if (!loggedInUser) {
                    navigate("/login");
                    return;
                }
                
                const [consultant] = await Promise.all([
                    getUserByEmail(loggedInUser.email, "consultants"),
                ]);

                if (consultant !== null) {
                    navigate("/dashboard");
                }

               
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [navigate,user]);

    const handleLanguageSelectionChange = (selectedKeys) => {
        setLanguageValues(new Set(selectedKeys));
    };

    const handleKeywordChange = (e) => {
        const value = e.target.value.trim();
        if (value && (e.key === "Enter" || value.endsWith(" "))) {
            e.preventDefault();
            if (!keywords.includes(value)) {
                setKeywords((prev) => [...prev, value]);
                setCurrentKeyword("");
            }
        } else {
            setCurrentKeyword(value);
        }
    };

    const handleKeywordRemove = (index) => {
        setKeywords((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = Object.fromEntries(new FormData(e.currentTarget));
        const loggedInUser = await checkAuthStatus();
        if (!loggedInUser) {
            navigate("/login");
            return;
        }
        console.log(loggedInUser)
        const submissionData = {
            ...formData,
            languages: Array.from(languageValues),
            keywords,
            ...calenderValue,
            image: loggedInUser.photoURL,
            email: loggedInUser.email,
            reviews: [],
            location:'',
            joined: new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
            totalExitAmount: 0,
            currency:"₹",
            bank: {
                ifsc: '',
                acountNumber: '',
                holderName: ''
            },
            commission:20,
            username: `${loggedInUser.photoURL.slice(10,15).trim().toLowerCase().replace(/\s+/g, '')}_${Date.now().toString(36)}`
        };
        createConsultant(submissionData)
        setAction(`Submitted: ${JSON.stringify(submissionData)}`);
        navigate('/dashboard')
    };

    const handleReset = () => {
        setAction("Form reset");
        setLanguageValues(new Set());
        setKeywords([]);
        setCurrentKeyword("");
    };


    const createConsultant = async (data) => {
        try {
            const docRef = await addDoc(collection(db, "consultants"), data);
            console.log("Consultant added with ID: ", docRef.id);
            notify.default('Your Personalized Consultant Dashboard is Ready')
            return docRef
        } catch (error) {
            console.error("Error adding consultant: ", error);
        }
    };

    if(user==null){
        return  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>   <Spinner size="xl" /> </div>
    }

    return (
        <div className="container mx-auto p-4 my-4 pt-28 flex justify-center">
            <Form
                className="w-full max-w-lg flex flex-col gap-4"
                validationBehavior="native"
                onReset={handleReset}
                onSubmit={handleSubmit}
            >
                <Input
                    isRequired
                    errorMessage="Please enter a valid display name"
                    label="Display Name"
                    name="displayname"
                    placeholder="Enter your display name"
                    type="text"
                />
                <Input
                    isRequired
                    disabled
                    errorMessage="Please enter a valid email"
                    label="Email"
                    defaultValue={user.email}
                    name="email"
                    type="email"
                />
                <Input
                    isRequired
                    errorMessage="Please enter a valid headline"
                    label="Headline"
                    name="headline"
                    placeholder="Enter your headline"
                    type="text"
                />
                <Select
                    isRequired
                    label="Speaking Languages"
                    placeholder="Select languages"
                    selectedKeys={Array.from(languageValues)}
                    name="languages"
                    selectionMode="multiple"
                    onSelectionChange={handleLanguageSelectionChange}
                    errorMessage="Please select at least one language"
                >
                    {languages.map((language) => (
                        <SelectItem key={language.key}>{language.label}</SelectItem>
                    ))}
                </Select>
                <Textarea
                    isRequired
                    label="Description"
                    name="description"
                    placeholder="Enter your description"
                    errorMessage="The description should be at least 55 characters long."
                    variant="bordered"
                />
                <Textarea
                    isRequired
                    label="Full Detaild experience"
                    name="experience"
                    placeholder="Enter your experience"
                    errorMessage="The experience should be at least 255 characters long."
                    variant="bordered"
                />
                <Input
                    isRequired
                    errorMessage="Please enter a price"
                    name="price"
                    label="Price"
                    placeholder="0.00/hr"
                    startContent={
                        <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 text-small">₹</span>
                        </div>
                    }
                    type="number"
                />

                <div className="flex flex-col gap-2 mx-1">
                    <label className="text-sm font-medium">Areas of Expertise Keywords</label>
                    <div className="min-h-[60px] p-3 border rounded-lg bg-white focus-within:ring-2 focus-within:ring-black-300">
                        <div className="flex flex-wrap gap-2">
                            {keywords.map((keyword, index) => (
                                <div
                                    key={index}
                                    className="flex text-sm items-center gap-1 px-3 py-1 bg-blue-100 rounded-full text-black-600"
                                >
                                    <span>{keyword}</span>
                                    <button
                                        type="button"
                                        onClick={() => handleKeywordRemove(index)}
                                        className="p-1 hover:bg-blue-200 rounded-full"
                                    >
                                        X
                                    </button>
                                </div>
                            ))}
                            <input
                                type="text"
                                value={currentKeyword}
                                onChange={(e) => setCurrentKeyword(e.target.value)}
                                onKeyDown={handleKeywordChange}
                                className="outline-none bg-transparent text-sm min-w-[300px]"
                                placeholder={keywords.length === 0 ? "Type keywords and press space or enter..." : ""}
                            />
                        </div>
                        <input type="hidden" name="keywords" value={JSON.stringify(keywords)} />
                    </div>
                </div>
                <div className="min-w-full mx-1 my-1">
                    <label className="text-sm font-medium">Select Your Available Time Slots</label>
                    <MultiSelectCalendar getSelectedValue={setCalenderValue} />
                </div>

                <div className="flex gap-2">
                    <Button color="primary" type="submit" size="sm">
                        Submit
                    </Button>
                    <Button type="reset" variant="flat" size="sm">
                        Reset
                    </Button>
                </div>
                {action && (
                    <div className="text-small text-default-500">
                        Action: <code>{action}</code>
                    </div>
                )}
            </Form>
        </div>
    );
}
