// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyC-shPt-b70aS8JgPKnZ_4WzfUbpPAaGVY",
//   authDomain: "adversity-ui.firebaseapp.com",
//   projectId: "adversity-ui",
//   storageBucket: "adversity-ui.firebasestorage.app",
//   messagingSenderId: "1085277970136",
//   appId: "1:1085277970136:web:ad7c750fad78e1e0cc01ad",
//   measurementId: "G-SH3T43CZEQ",
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const provider = new GoogleAuthProvider();
// const db = getFirestore(app);

// const checkAuthStatus = () => {
//   return new Promise((resolve, reject) => {
//     onAuthStateChanged(auth, (user) => {
//       if (user) {
//         console.log("User is logged in:", user);
//         resolve(user); // Resolve with the user object
//       } else {
//         console.log("No user is logged in.");
//         resolve(null); // Resolve with null if no user is logged in
//       }
//     }, reject); // Handle potential errors
//   });
// };

// export { auth, provider, signInWithPopup, db, checkAuthStatus };



import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC-shPt-b70aS8JgPKnZ_4WzfUbpPAaGVY",
  authDomain: "adversity-ui.firebaseapp.com",
  projectId: "adversity-ui",
  storageBucket: "adversity-ui.firebasestorage.app",
  messagingSenderId: "1085277970136",
  appId: "1:1085277970136:web:ad7c750fad78e1e0cc01ad",
  measurementId: "G-SH3T43CZEQ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// Add the Calendar API scope
// provider.addScope('https://www.googleapis.com/auth/calendar');

// Function to handle Google sign-in and get the access token
const signInAndGetToken = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    console.log("User is logged in:", user);

    // Get the Google Access Token
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken; // This is the token for Google Calendar API

    console.log("Google Access Token:", token);

    return token; // You can now use this token to make API requests to Google Calendar

  } catch (error) {
    console.error("Error during sign-in:", error);
  }
};

const checkAuthStatus = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is logged in:", user);
        resolve(user); // Resolve with the user object
      } else {
        console.log("No user is logged in.");
        resolve(null); // Resolve with null if no user is logged in
      }
    }, reject); // Handle potential errors
  });
};

export { auth, provider, signInWithPopup, db, checkAuthStatus, signInAndGetToken };

