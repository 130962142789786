import { Icon } from "@iconify/react";

import { Avatar, AvatarGroup, InputOtp, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, } from "@nextui-org/react";
import { filterEvents } from "../../utils/eventData";
import { useNavigate } from "react-router-dom";
import Rating from '../Rating/Rating'
import { Textarea, Chip } from "@nextui-org/react";
import { Calendar, Clock, IndianRupee, Banknote, Tickets } from 'lucide-react';
import { useState } from "react";
import notify from "../../utils/toastService";
import { addReviewToConsultant } from "../../utils/fetchFirebaseData";

const Previous = ({ eventData = [] }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate()
    eventData = filterEvents(eventData).previous
    return (
        <div className="min-h-[400px] max-h-[800px] overflow-auto">
            <div className="flex font-medium text-default-500">
                <Icon className="self-start" icon="solar:calendar-add-broken" height="22" />
                <h4 className="text-base dark:text-white pe-6 ps-2">Consultation History</h4>
            </div>
            {eventData && eventData.length > 0 ? (

                eventData.map((event, index) => (
                    <div key={event.id || index} className="mt-8">
                        <div className="border border-slate-300 hover:border-indigo-600  my-5 rounded-lg p-3 flex flex-wrap gap-8 justify-between">
                            {/* Avatar Group */}
                            <div className="flex gap-5">
                                <div className="self-center">
                                    <AvatarGroup isBordered isDisabled>
                                        <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026704d" />
                                        <Avatar src="https://i.pravatar.cc/150?u=a04258114e29026302d" />
                                    </AvatarGroup>
                                </div>
                                {/* OTP Input */}
                                <div className="flex self-center">
                                    <InputOtp description="OTP to Verify Consultant" isReadOnly defaultValue={event.consumer_otp || "----"} length={4} size="sm" />
                                </div>
                            </div>

                            <div className="flex flex-wrap gap-5 self-center">
                                <div className="flex items-center gap-2">
                                    <div className="p-2 bg-blue-50 rounded-md">
                                        <Calendar className="text-blue-500 h-4 w-4" />
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500 font-medium">Date</span>
                                        <span className="text-sm text-blue-950">{event.time.date}</span>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="p-2 bg-blue-50 rounded-md">
                                        <Clock className="text-blue-500 h-4 w-4" />
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500 font-medium">Time</span>
                                        <span className="text-sm text-blue-950">{event.time.time}</span>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="p-2 bg-blue-50 rounded-md">
                                        <IndianRupee className="text-blue-500 h-4 w-4" />
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500 font-medium">Cost</span>
                                        <span className="text-sm text-blue-950">{event.payment.price}</span>
                                    </div>
                                </div>

                                <div className="flex items-center gap-2">
                                    <div className="p-2 bg-blue-50 rounded-md">
                                        <Banknote className="text-blue-500 h-4 w-4" />
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500 font-medium">Payment Status</span>
                                        <span className="text-sm text-blue-950">{event.amount_status.toUpperCase()}</span>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="p-2 bg-blue-50 rounded-md">
                                        <Tickets className="text-blue-500 h-4 w-4" />
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500 font-medium">Session Status</span>
                                        <span className="text-sm text-blue-950">{event.status.toUpperCase()}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex gap-5 self-center flex-wrap">
                                <Button onPress={onOpen} size="sm" className="bg-slate-200 rounded text-blue-950 shadow-sm py-5 px-4 font-semibold tracking-wide"> <Icon icon="ic:baseline-rate-review" className="text-xl" /> Add Review </Button>
                                <Button onPress={() => window.open('/profile/' + event.consultant_username, '_blank')} size="sm" className="bg-blue-950 rounded text-white shadow-sm py-5 px-4 font-semibold tracking-wide"> <Icon icon="ic:baseline-video-call" className="text-xl" /> Plan Meeting Again </Button>
                                <ModalComponent isOpen={isOpen} onClose={onClose} detail={event} />
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="flex justify-center py-14">
                    <img className="h-64 object-contain" src="https://img.freepik.com/premium-vector/no-data-concept-illustration_86047-488.jpg?semt=ais_hybrid" alt="No data" />
                </div>
            )}
        </div>
    )

}

export default Previous;

const ModalComponent = ({ isOpen, onClose, detail }) => {
    const [review, setReview] = useState("");
    const [rating, setRating] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({ review: "", rating: "" });

    const handleReviewChange = (e) => {
        const value = e.target.value;
        setReview(value);

        // Validate review length
        if (value.length < 25) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                review: "The description should be at least 25 characters long.",
            }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, review: "" }));
        }
    };

    const handleRatingChange = (value) => {
        setRating(value);

        // Validate minimum rating value
        if (value < 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                rating: "Please select at least a 1-star rating.",
            }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, rating: "" }));
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prevent multiple submissions
        if (isSubmitting) return;

        // Final validation before submission
        if (review.length < 25 || rating < 1) {
            setErrors({
                review: review.length < 25 ? "The description should be at least 25 characters long." : "",
                rating: rating < 1 ? "Please select at least a 1-star rating." : "",
            });
            return;
        }

        // Prepare review data
        const data = {
            username: "Anonymous",
            rating,
            date: getCurrentDate(),
            review: review.length > 300 ? review.slice(0, 298) : review,
            email: detail.consumer,
        };

        setIsSubmitting(true); // Start submission process

        try {
            const response = await addReviewToConsultant(detail.consultant_id, data);
            if (response.success) {
                onClose(); // Close modal after successful submission
            }
        } catch (error) {
            console.error("Error adding review: ", error);
        } finally {
            setReview('')
            setRating(0)
            setIsSubmitting(false); // Reset submission state
        }
    };


    return (
        <Modal backdrop="blur" isOpen={isOpen} onClose={onClose}>
            <ModalContent className="mb-1 pb-4">
                <ModalHeader className="flex flex-col gap-1 text-xl">Add Review</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <Textarea className="max-w-full" value={review} onChange={handleReviewChange} errorMessage={errors.review} label="Review" placeholder="Enter your Review" variant="bordered" />
                        {errors.review && <p className="text-red-500 text-sm">{errors.review}</p>}

                        <div className="mx-auto mt-4">
                            <Rating total={5} initialValue={rating} onChange={handleRatingChange} />
                            {errors.rating && <p className="text-red-500 text-sm">{errors.rating}</p>}
                        </div>

                        <Button color="primary" variant="light" className="mt-5" type="submit" disabled={!!errors.review || !!errors.rating} > Submit </Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const getCurrentDate = () => {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
    return formatter.format(now);
};

