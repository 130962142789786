import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { queryUserData } from "../../utils/fetchFirebaseData";
import Background from "../../components/BackgroundAnimation/Background";
import { Icon } from '@iconify/react';
import { Spinner } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, Button, useDisclosure } from "@nextui-org/react";
import ReviewCard from "../../components/CardReview/CardReview";
import BookConsultation from "../../components/BookConsultation/BookConsultation";

const Profile = () => {
    const { username } = useParams();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchUserData = useCallback(async () => {
        if (!username) return navigate("/");
        try {
            setLoading(true);
            const data = await queryUserData("consultants", "username", username, []);
            if (!data) {
                navigate("/");
            } else {
                setUserData(data);
                console.log(data)
            }
        } catch (err) {
            console.error("Error fetching user data:", err);
            setError("Failed to load user data. Please try again later.");
        } finally {
            setLoading(false);
        }
    }, [username, navigate]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const handleImageError = (e) => {
        e.target.src = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHUndSzxcF1UbSXX3bVILVaUbSIhoc_GEA8g&s';
    };

    if (loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>   <Spinner size="xl" /> </div>;
    if (error) return <div>{error}</div>;
    if (!userData) return null;

    return (
        <div className="relative isolate">
            <Background />
            <div className="w-full h-[180px] bg-transparent overflow-hidden">
                <img
                    src="https://img.freepik.com/free-photo/spectrum-flashes-coloured-light_23-2151792456.jpg"
                    alt="Profile Background"
                    className="w-full h-full object-cover"
                />
            </div>
            <div className="container mx-auto px-5 max-w-6xl">
                <div className="relative bottom-8">
                    <div className="flex justify-between items-center">
                        <img
                            className="rounded-full h-24"
                            src={userData.image}
                            alt="User profile"
                            onError={handleImageError}
                        />
                        <div className="self-end">
                            <Button
                                size="md"
                                className="bg-blue-950 rounded text-white shadow-lg py-5 px-4 font-semibold tracking-wide"
                                onPress={onOpen}
                            >
                                <Icon icon="ic:baseline-video-call" className="text-xl" />
                                Book Meeting
                            </Button>
                        </div>
                    </div>
                    <div className="py-5 mb-5 flex justify-between flex-wrap gap-5 border-b-1 border-gray-200">
                        <div >
                            <div className="font-semibold text-lg">{userData.displayname}</div>
                            <div className="text-sm">
                                <div className="text-base">{userData.headline}</div>
                                <div className="text-gray-500 py-2 flex gap-3 flex-wrap">
                                    <span className="flex items-center"> {'@' + userData.username} </span>
                                   {userData.location.length ?  <span className="flex items-center"> <Icon icon="mdi:map-marker" className="mr-1" />{userData.location}</span>: null}
                                    <span className="flex items-center">
                                        <Icon icon="mdi:calendar" className="mr-1" />  { 'Joined ' +userData.joined}
                                    </span>
                                    <span className="flex items-center">
                                        <Icon icon="mdi:star" className="mr-1 text-orange-500" /> { (userData.reviews.reduce((accum,curr)=> accum + curr.rating,0) / userData.reviews.length ).toFixed(1)}({userData.reviews.length})
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="self-center">
                            <div className="px-0 py-2 rounded-lg ">
                                <h2 className="text-2xl font-medium  mb-1">{userData.currency + ' ' + userData.price + ' /hr'}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-6 flex-wrap">
                        <div className="flex flex-col gap-5 border-b-1  pb-10 border-gray-200">
                            <div>
                                <div className="text-lg font-medium py-3">{'About'}</div>
                                <div className="text-gray-600 text-sm break-words">
                                    {userData.description}
                                </div>
                            </div>
                            <div>
                                <div className="text-lg font-medium py-3">{'Experience'}</div>
                                <div className="text-gray-600 text-sm break-words">
                                    {userData.description}
                                </div>
                            </div>
                            <div>
                                <div className="text-lg font-medium py-3">{'Languages'}</div>
                                <div className="text-gray-600 text-sm flex gap-5 flex-wrap">
                                    {userData.languages.map((item, index) => (
                                        <Button key={index} size="sm" className="border-1 rounded text-grey" variant="bordered">
                                            <Icon icon="ic:baseline-translate" className="text-lg" />
                                            {item.toUpperCase()}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="text-lg font-medium py-3">{'Specialization'}</div>
                                <div className="text-gray-600 text-sm flex gap-5 flex-wrap">
                                    {userData.keywords.map((item, index) => (
                                        <Button key={index} size="sm" className="border-1 rounded text-grey" variant="bordered">
                                            {item.toUpperCase()}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="max-w-full">
                            <div className="text-lg font-medium my-3">Reviews</div>
                            <div className="flex flex-col gap-6 lg:min-w-[448px] sm:min-w-0">
                                {userData.reviews.map(review => <ReviewCard rating ={review.rating}  date  = {review.date}  review ={review.review} /> )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent isOpen={isOpen} onClose={onClose} userData={userData} />
        </div>
    );
};

const ModalComponent = ({ isOpen, onClose, userData }) => {
    return (
        <Modal backdrop="blur" isOpen={isOpen} onClose={onClose}>
            <ModalContent className="mb-1 pb-4">
                <ModalHeader className="flex flex-col gap-1 text-xl">Book a session</ModalHeader>
                <ModalBody>
                    <BookConsultation data={userData} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default Profile;
