// import {Textarea} from "@nextui-org/react";
// import {Button} from "@nextui-org/react";
// import { useState } from "react";

// export default function  GlobalSearch() {
//   const [description, setDescription] = useState('')
//   const getUserText = () => {
//     console.log(description)
//   }
//   return (
//     <div>
//         <Textarea
//       className="max-w-full"
//       description=" Get Personalized Consultation by Simply Sharing Your Problem"
//       label="Find the Right Consultant "
//       placeholder="Enter your description"
//       variant="faded"
//       onChange={(e)=> setDescription(e.target.value)}
//     />
//         <Button onPress={getUserText} isDisabled={!description} size="sm" color="primary" variant="bordered" className="my-4">
//         Search
//       </Button>
//     </div>
    
//   );
// }



import { Textarea } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { FaMicrophone, FaMicrophoneAltSlash } from "react-icons/fa"; // Import microphone icons
import { useNavigate } from "react-router-dom";

export default function GlobalSearch() {
  const navigate =  useNavigate()
  const [description, setDescription] = useState('');
  const [isListening, setIsListening] = useState(false); // State to track listening status

  // Initialize SpeechRecognition
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();

  useEffect(() => {
    // Set up speech recognition
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    recognition.onstart = () => {
      setIsListening(true); // Set listening state to true when recognition starts
    };

    recognition.onend = () => {
      setIsListening(false); // Set listening state to false when recognition ends
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setDescription(transcript);
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error', event.error);
      setIsListening(false); // Reset listening state if there's an error
    };
  }, []);

  const startVoiceRecognition = () => {
    recognition.start();
  };

  const getUserText = () => {
    console.log(description);
    navigate('/search/'+description)
  };

  return (
    <div className="relative">
      <Textarea
        className="max-w-full"
        description="Get Personalized Consultation by Simply Sharing Your Problem"
        label="Find the Right Consultant"
        placeholder="Enter your description"
        variant="faded"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      
      {/* Voice Input Icon inside the Textarea */}
      <div
        className={`absolute right-3 top-1/4 transform -translate-y-1/4 cursor-pointer ${isListening ? 'text-green-500' : 'text-gray-500'}`}
        onClick={startVoiceRecognition}
      >
        {isListening ? (
          <FaMicrophoneAltSlash size={20} /> // Show "microphone-off" icon when listening
        ) : (
          <FaMicrophone size={20} /> // Show "microphone" icon when not listening
        )}
      </div>

      <Button
        onPress={getUserText}
        isDisabled={!description}
        size="sm"
        color="primary"
        variant="bordered"
        className="my-4"
      >
        Search
      </Button>
    </div>
  );
}

