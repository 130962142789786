import React from "react";
import { auth, provider, signInWithPopup, db } from "../../utils/firebaseAuth";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import getUserByEmail from "../../utils/getUserDetail";
import notify from "../../utils/toastService";

const GoogleLogin = ({ prevPath = "/dashboard" }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const createConsumer = async (email) => {
        const data = {
            balance: "50",
            currency: "₹",
            email: email,
        };
        try {
            const docRef = await addDoc(collection(db, "consumers"), data);
            console.log("Consumer added with ID: ", docRef.id);
            notify.default("Your Personalized Dashboard is Ready");
            return docRef;
        } catch (error) {
            console.error("Error adding consumer: ", error);
        }
    };

    const handleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const userDetails = {
                name: user.displayName,
                email: user.email,
                photo: user.photoURL,
                uid: user.uid,
                phoneNumber: user.phoneNumber,
                emailVerified: user.emailVerified,
                providerId: user.providerData[0]?.providerId,
                creationTime: user.metadata.creationTime,
                lastSignInTime: user.metadata.lastSignInTime,
            };

            const userData = await getUserByEmail(userDetails.email, "consumers");
            if (userData === null) {
                await createConsumer(userDetails.email);
            }

            if (location.pathname === prevPath) {
                // Refresh the current page
                window.location.reload();
            } else {
                // Navigate to the previous path
                navigate(prevPath, { state: { userDetails } });
            }
        } catch (error) {
            console.error("Error logging in with Google:", error.message);
        }
    };

    return (
        <div className="flex h-screen w-full items-center justify-center bg-gray-100">
            <div className="flex w-full max-w-sm flex-col rounded-lg bg-white p-8 shadow-lg">
                <Button
                    onPress={handleLogin}
                    startContent={<Icon icon="flat-color-icons:google" width={24} />}
                    variant="bordered"
                    className="text-gray-700 border-gray-300 hover:bg-gray-50"
                >
                    Continue with Google
                </Button>
            </div>
        </div>
    );
};

export default GoogleLogin;
