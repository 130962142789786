import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Avatar, User, } from "@nextui-org/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../utils/firebaseAuth";


export default function UserDropdown(props) {
    const navigate = useNavigate();
    const { data }  = props
    useEffect(() => console.log(props), [])

    const handleLogout = async () => {
        try {
            await signOut(auth);
            console.log("User logged out successfully");
            navigate("/");
        } catch (error) {
            console.error("Error during logout:", error.message);
        }
    };

    return (
        <div className="flex items-center gap-4">
            <Dropdown placement="bottom-start">
                <DropdownTrigger>
                    <User
                        as="button"
                        avatarProps={{
                            isBordered: true,
                            src: data.photo===null ? "https://i.pravatar.cc/150?u=a042581f4e29026024d" : data.photo,
                        }}
                        className="transition-transform"
                        description={(data.email || "No Email").slice(0, 20)}
                        name={(data.name || "Anonymous").slice(0, 20)}
                    />
                </DropdownTrigger>
                <DropdownMenu aria-label="User Actions" variant="flat">
                    <DropdownItem onPress={() => navigate("/dashboard")} key="settings">Dashboard</DropdownItem>
                    <DropdownItem href="https://forms.gle/AKuA5XxWn4QWgK6q9" target="_blank" key="help_and_feedback">Help & Feedback</DropdownItem>
                    <DropdownItem onPress={handleLogout} key="logout" color="danger">
                        Log Out
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}
