import { useNavigate, useParams } from 'react-router-dom';
import '../../assets/Adversity.png'
import { useState } from 'react';
const SearchNavbar = () => {
    const { prompt } = useParams()
    const navigate = useNavigate()
    const [searchPrompt , setSearchPrompt] = useState(prompt)

    return (
        <div className='p-4 px-2 container mx-auto max-w-8xl flex flex-row'>
            <div className="hidden lg:flex lg:flex-1 self-center me-32">
                <a href="/" className="-m-1.5 p-1.5">
                    <span className="text-indigo-700 text-2xl font-bold">ADVERSITY</span>
                </a>
            </div>


            <div class="w-full">
                <label for="default-search" class="text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e)=> setSearchPrompt(e.target.value)  }  value={searchPrompt} placeholder="Search" required />
                    <button onClick={()=>navigate('/search/'+searchPrompt)} type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                </div>
            </div>

            <div>

            </div>
        </div>
    )
}

export default SearchNavbar;