"use client";
import React, { useEffect, useState } from "react";
import { Button, Card, Chip, cn, Badge } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import CurrencyCounter from "../CurrencyCounter/CurrencyCounter";
import { queryUserData } from "../../utils/fetchFirebaseData";
import Upcoming from "./Upcoming";
import Previous from "./Previous";

const defaultData = [
  {
    title: "Total Consultations",
    value: "200",
    change: "%",
    changeType: "positive",
    trendChipPosition: "top",
    iconName: "solar:users-group-rounded-linear",
    buttonName: "",
    isCounting: false,
  },
  {
    title: "Hold Balance",
    value: "₹ 0",
    change: "%",
    changeType: "neutral",
    trendChipPosition: "top",
    iconName: "solar:hand-money-bold",
    buttonName: '',
    isCounting: false
  },
  {
    title: "Current Balance",
    value: "₹ 5,400",
    change: "%",
    changeType: "neutral",
    trendChipPosition: "top",
    iconName: "solar:wallet-money-outline",
    buttonName: "Withdraw Request",
    isCounting: true,
  },
];

export default function PersonalDashboard({ userData }) {
  const [data, setData] = useState(defaultData);
  const [eventData, setEventData] = useState(null);

  const getEventData = async () => {
    try {
      const tempEventData = await queryUserData("events", "consumer", userData.email, [], false);
  
      const totalEvents = tempEventData?.length || 0;
      const totalHeldAmount = tempEventData
        ?.filter(item => item.amount_status === 'hold')
        .reduce((sum, item) => sum + parseFloat(item.payment.price || 0), 0) || 0;
  
      setData((prevData) => [
        { ...prevData[0], value: totalEvents.toString() },
        { ...prevData[1], value: `${userData.currency} ${totalHeldAmount.toFixed(2)}` },
        { ...prevData[2], value: `${userData.currency} ${userData.balance}` },
      ]);
  
      setEventData(tempEventData || []);
    } catch (error) {
      console.error("Error fetching event data:", error);
  
      // Fallback to default state in case of an error
      setData((prevData) => [
        { ...prevData[0], value: "0" },
        { ...prevData[1], value: `${userData.currency} 0.00` },
        { ...prevData[2], value: `${userData.currency} ${userData.balance}` },
      ]);
  
      setEventData([]);
    }
  };
  

  useEffect(() => {
    getEventData();
  }, [userData]);

  if (eventData === null) return <>Loading...</>;

  return (
    <div>
      <dl className="grid w-full grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
        {data.map(
          ({ title, value, change, changeType, iconName, trendChipPosition, buttonName, isCounting }, index) => (
            <Card key={index} className="border border-transparent dark:border-default-100">
              <div className="flex p-4">
                <div
                  className={cn("mt-1 flex h-8 w-8 items-center justify-center rounded-md", {
                    "bg-success-50": changeType === "positive",
                    "bg-warning-50": changeType === "neutral",
                    "bg-danger-50": changeType === "negative",
                  })}
                >
                  <Icon className={`text-${changeType}`} icon={iconName} width={20} />
                </div>

                <div className="flex flex-col gap-y-2">
                  <dt className="mx-4 text-small font-medium text-default-500">{title}</dt>
                  {isCounting ? (
                    <CurrencyCounter value={value} />
                  ) : (
                    <dd className="px-4 text-2xl font-semibold text-default-700">{value}</dd>
                  )}
                </div>

                <Chip
                  className={cn("absolute right-4", {
                    "top-4": trendChipPosition === "top",
                    "bottom-4": trendChipPosition === "bottom",
                  })}
                  classNames={{
                    content: "font-semibold text-[0.65rem]",
                  }}
                  color={changeType === "positive" ? "success" : changeType === "neutral" ? "warning" : "danger"}
                  radius="sm"
                  size="sm"
                  startContent={
                    <Icon
                      height={12}
                      icon={`solar:arrow-right-${changeType === "positive" ? "up" : changeType === "neutral" ? "" : "down"}-linear`}
                      width={12}
                    />
                  }
                  variant="flat"
                >
                  {change}
                </Chip>
              </div>

              <div className="bg-default-100">
                <Button
                  fullWidth
                  className="flex justify-start text-xs text-default-500 data-[pressed]:scale-100"
                  radius="none"
                  variant="light"
                >
                  {buttonName}
                </Button>
              </div>
            </Card>
          )
        )}
      </dl>

      <div className="mt-10 px-1">
        {/* Upcoming Consultations */}
        <Upcoming eventData={eventData} />

        {/* Consultation History */}
        <Previous eventData={eventData} />
      </div>
    </div>
  );
}
